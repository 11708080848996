import $ from 'jquery'
$(document).ready( initImageSlider )

function initImageSlider(){
  if ($('.js-image-slider').length) {
    $('.js-image-slider').slick({
      arrows: true,
      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '31%',
      prevArrow: '<button type="button" class="slick-prev"></button>',
      nextArrow: '<button type="button" class="slick-next"></button>',
      responsive: [
        {
          breakpoint: 960,
          settings: {
            centerPadding: '15%'
          }
        },
        {
          breakpoint: 700,
          settings: {
            centerPadding: '30px'
          }
        }
      ]
    });
  }
}
