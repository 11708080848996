import $ from 'jquery'

$(document).ready( initTestimonialsSlider )

function initTestimonialsSlider(){
  const sliderWrapper = $('.js-testimonials-slider');
  if (sliderWrapper.length) {
    sliderWrapper.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      dots: true,
      nextArrow: '<button type="button" class="slick-next"><svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.61732e-06 18.5C2.51054e-06 8.28273 8.28274 -2.51054e-06 18.5 -1.61732e-06C28.7173 -7.24099e-07 37 8.28273 37 18.5C37 28.7173 28.7173 37 18.5 37C8.28273 37 7.24099e-07 28.7173 1.61732e-06 18.5ZM18.5 2.11428C9.45042 2.11428 2.11429 9.45042 2.11429 18.5C2.11429 27.5496 9.45042 34.8857 18.5 34.8857C27.5496 34.8857 34.8857 27.5496 34.8857 18.5C34.8857 9.45042 27.5496 2.11428 18.5 2.11428Z" fill="black"/><path d="M16.2795 17.7598L16.2795 8.87965L25.1597 17.7598L16.2795 26.6399L16.2795 17.7598Z" fill="black"/></svg></button>',
      prevArrow: '<button type="button" class="slick-prev"><svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M37 18.5C37 28.7173 28.7173 37 18.5 37C8.28273 37 0 28.7173 0 18.5C0 8.28273 8.28273 0 18.5 0C28.7173 0 37 8.28273 37 18.5ZM18.5 34.8857C27.5496 34.8857 34.8857 27.5496 34.8857 18.5C34.8857 9.45042 27.5496 2.11429 18.5 2.11429C9.45042 2.11429 2.11429 9.45042 2.11429 18.5C2.11429 27.5496 9.45042 34.8857 18.5 34.8857Z" fill="black"/><path d="M20.7205 19.2402V28.1204L11.8403 19.2402L20.7205 10.3601V19.2402Z" fill="black"/></svg></button>',
    });
  }
}
