import $ from 'jquery'

$(document).ready( initCircleSlider )


function initCircleSlider(){

  // circles content slider
  const circleSliderWrapper = $('.js-circle-slider');
  if (circleSliderWrapper.length) {
    circleSliderWrapper.slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
      dots: true,
      infinite: false,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 760,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    });
  }
}